import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';

import ArrowDownIcon from 'assets/icons/arrow-drop-down-down.svg';
import ArrowRightIcon from 'assets/icons/arrow-drop-down-right.svg';
import { tablet } from 'styles/breakpoints';

import { DynamicImage, PrimaryButton, Text } from 'components';

import Layout from 'layouts/Layout';
import { useRouter } from 'apis/history';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
}

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
`;

const Container = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 1rem 4.5rem;
  width: 1204px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 0 1rem 3rem;
    height: unset;
  }
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 0.5rem 1.5rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e4e4eb;
  justify-content: space-between;
  @media ${tablet} {
    padding-bottom: 1rem;
  }
`;

const Title = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
  color: 'dark80',
})`
  color: #28293d;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: normal;
  }
`;

const List = styled.div`
  margin-top: 3rem;
  max-width: 100%;
  width: 832px;
  div:nth-child(1n):not(:last-child) {
    margin-bottom: 0.75rem;
  }

  @media ${tablet} {
    div:nth-child(1n):not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const ArrowUp = styled(ArrowRightIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const Answer = styled(Text)`
  padding-bottom: 1.5rem;
  color: #28293d;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;
const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  background: ${({ theme }) => theme.colors.primary};
  padding: 0.75rem 1.5rem;
  min-width: 10.22rem;
  max-width: 15rem;
  div {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media ${tablet} {
    min-width: 7.06rem;
    min-height: unset;
  }
`;
const FAQ: FC<PeopleAskUsProps> = () => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);
  const { goToLanding } = useRouter();
  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const isExpanded = (index: number) => expandedQuestions.includes(index);

  return (
    <Layout
      seoTitle="FAQ"
      seoName="FAQ"
      seoDescription="Find out more about Nordastro"
      logoVariant={'left'}
      hasDivider={true}
      rightComponent={
        <Button onClick={() => goToLanding()}>Start the quiz</Button>
      }
    >
      <Wrapper>
        <Container>
          <Title>Frequently asked questions</Title>
          <List>
            <QuestionContainer expanded={isExpanded(1)}>
              <TitleContainer onClick={() => handleClick(1)}>
                <Question>How is Nordastro personalized?</Question>
                {isExpanded(1) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(1)}>
                <div ref={childRef}>
                  <Answer>
                    Your Nordastro book is created by analyzing the position of
                    planets and stars at the time of your birth. This
                    information allows us to generate a precise birth chart. Our
                    expert astrologers then analyze this chart to provide deep
                    insights into your personality, strengths, and life path.
                  </Answer>
                  <Answer>
                    We tailor compatibility reports based on your unique
                    astrological profile, offering personalized advice to
                    enhance your relationships. Additionally, we recommend
                    crystals that resonate with your specific chart and provide
                    Tarot card readings informed by your astrological data.
                  </Answer>
                  <Answer>
                    To help you navigate life&apos;s opportunities and
                    challenges, we compile an astrological events calendar
                    highlighting key dates and transits specific to you.
                  </Answer>
                  <Answer>
                    Finally, you can choose the color and illustration for the
                    custom cover, which features your name, making your
                    Nordastro book unique, personal, and one-of-a-kind.
                  </Answer>
                  <Answer>
                    Every aspect of the Nordastro book reflects your
                    individuality.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(2)}>
              <TitleContainer onClick={() => handleClick(2)}>
                <Question>What will I find inside my book?</Question>
                {isExpanded(2) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(2)}>
                <div ref={childRef}>
                  <Answer>
                    Inside your personalized Nordastro book, you will find:
                  </Answer>
                  <Answer>
                    <ul>
                      <li>
                        A personalized birth chart analysis to help you discover
                        the secrets of your personality, strengths, and life
                        path.
                      </li>
                      <li>
                        Love & relationship insights tailored to you. By
                        understanding your zodiac compatibility, you will
                        discover how to connect with others and who is your
                        perfect partner.
                      </li>
                      <li>
                        Career & success guidance that will enhance your
                        professional life. You will achieve your career goals by
                        uncovering opportunities perfect for your unique
                        strengths.
                      </li>
                      <li>
                        The personality & life path profile examines your
                        traits, strengths, and areas for growth. Knowing this
                        allows you to embrace your true self.
                      </li>
                      <li>
                        Personal astrological remedies such as rituals to
                        balance your energy, and overcome challenges tailored to
                        your astrological needs.
                      </li>
                      <li>
                        Compatibility reports will guide you in creating and
                        enhancing your relationships.
                      </li>
                      <li>
                        Crystal guide on which crystals will improve your
                        everyday life, tailored to your astrological profile.
                      </li>
                      <li>
                        Tarot card insights for readings and interpretations of
                        Tarot based on your unique astrological data.
                      </li>
                      <li>
                        Astrological events calendar with the key dates and
                        transits specific to you.
                      </li>
                      <li>
                        And additional chapters on divination & astrology, a
                        guide to palmistry, numerology, history of astrology,
                        modern astrology and so much more!
                      </li>
                    </ul>
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(3)}>
              <TitleContainer onClick={() => handleClick(3)}>
                <Question>How long will it take to receive my book?</Question>
                {isExpanded(3) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(3)}>
                <div ref={childRef}>
                  <Answer>
                    We start working on your book as soon as you order! Your
                    ebook will reach your inbox within 24 hours, and it should
                    take 8-16 business days for the paperback to arrive.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(4)}>
              <TitleContainer onClick={() => handleClick(4)}>
                <Question>
                  I&apos;m new to astrology. Is this book right for me?
                </Question>
                {isExpanded(4) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(4)}>
                <div ref={childRef}>
                  <Answer>
                    Yes! When you start the process, we ask if you&apos;re new
                    to astrology. If you say “Yes,” we make sure the book is
                    extra beginner-friendly. Nordastro guides you step-by-step
                    through your unique birth chart, explaining each element in
                    clear, easy-to-understand language. This way, you feel
                    confident and informed, with no guesswork involved.
                    You&apos;ll find exploring astrology fun, insightful, and
                    incredibly rewarding!
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
            <QuestionContainer expanded={isExpanded(5)}>
              <TitleContainer onClick={() => handleClick(5)}>
                <Question>
                  Will the book help me with my specific issues and goals?
                </Question>
                {isExpanded(5) ? <ArrowUp /> : <ArrowDown />}
              </TitleContainer>

              <AnswerContainer expanded={isExpanded(5)}>
                <div ref={childRef}>
                  <Answer>
                    That’s exactly why we created Nordastro. We understand that
                    each person has a unique astrological profile, and generic
                    guidance often falls short. Our book is designed to reflect
                    what makes you special and align with your personal goals
                    and challenges. By tailoring insights to your specific birth
                    chart and your quiz answers, Nordastro can turn
                    uncertainties into clear guidance. It&apos;s all about
                    making life more fulfilling, improving relatonships and
                    helping you achieve your dreams with personalized
                    astrological insights.
                  </Answer>
                </div>
              </AnswerContainer>
            </QuestionContainer>
          </List>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default React.memo(FAQ);

const StyledImage = styled(DynamicImage)``;

const UnderscoreLink = styled.a`
  text-decoration: underline !important;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  padding-top: 4.5rem;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 416px);
  height: 100%;
  @media ${tablet} {
    z-index: 0;
    padding-top: 1.5rem;
  }
`;
